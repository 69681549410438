import { Dialog } from "@ariakit/react";
import { Link } from "@remix-run/react";
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import { X } from "lucide-react";
import pluralize from "pluralize";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import type {
  Community,
  Game,
  HappeningNowActivity,
  Post,
  Quest,
} from "~/types";
import { cn } from "~/util/css";
import { formatNumber } from "~/util/number";
import { parseData } from "~/util/parse-data";

function ClaimedDripNotification(props: { activity: HappeningNowActivity }) {
  const totalPoints = props.activity.data.reduce(
    (acc: number, data: string) => {
      const parsedData = parseData(data);
      return acc + (parsedData.points || 0);
    },
    0,
  );

  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        snatched <strong>{formatNumber(totalPoints)} DRIP!</strong>
      </div>
    </Item>
  );
}

function SignupNotification(props: { activity: HappeningNowActivity }) {
  const data = parseData(props.activity.data[0]);
  const [username, setUsername] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (data.referrer_id) {
      getUsernames([data.referrer_id]).then((users) => {
        setUsername(users[data.referrer_id]);
      });
    }
    setLoading(false);
  }, [data.referrer_id]);

  if (loading) {
    return <SkeletonItem />;
  }

  return (
    <Item
      activity={props.activity}
      className={
        data.referrer_id
          ? "border border-[#B999FF] bg-gradient-to-b from-[#dcafff] to-[#bfddff]"
          : ""
      }
    >
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        joined Soulbound
        {data.referrer_id && (
          <>
            {" "}
            , invited by{" "}
            <Link to={`/${username}`} className="font-bold">
              {username}
            </Link>
          </>
        )}{" "}
        🥳
      </div>
    </Item>
  );
}

function FollowedUserNotification(props: { activity: HappeningNowActivity }) {
  const [usernames, setUsernames] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const userIds = new Set<string>(
        props.activity.data.map(
          (data: string) => parseData(data).target_user_id,
        ),
      );

      const uniqueUserIds = Array.from(userIds);

      const users = await getUsernames(uniqueUserIds);
      setUsernames(uniqueUserIds.map((id) => users[id]));
      setLoading(false);
    };

    fetchData();
  }, [props.activity]);

  if (loading) {
    return <SkeletonItem />;
  }

  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        started following {}
        {usernames?.length === 1 ? (
          <Link to={`/${usernames[0]}`} className="font-bold">
            {usernames[0]}
          </Link>
        ) : (
          <>
            <strong>{usernames?.length} </strong>
            {pluralize("soulmate", usernames?.length)} ❤️
          </>
        )}
      </div>
    </Item>
  );
}

function MentionedGameNotification(props: { activity: HappeningNowActivity }) {
  if (props.activity.data.length === 0) return null;

  const data = parseData(props.activity.data[0]);
  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        mentioned 🗣️{" "}
        <Link to={`/games/${data.mentionId}`} className="font-bold">
          {data.mentionText}
        </Link>{" "}
        in a{" "}
        <Link to={`/post/${data.postId}`} className="font-bold">
          post
        </Link>
      </div>
    </Item>
  );
}

function MentionedCommunityNotification(props: {
  activity: HappeningNowActivity;
}) {
  if (props.activity.data.length === 0) return null;

  const data = parseData(props.activity.data[0]);
  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        mentioned 🗣️{" "}
        <Link to={`/communities/${data.mentionId}`} className="font-bold">
          {data.mentionText}
        </Link>{" "}
        in a{" "}
        <Link to={`/post/${data.postId}`} className="font-bold">
          post
        </Link>
      </div>
    </Item>
  );
}

function TipSentNotification(props: { activity: HappeningNowActivity }) {
  const postIds = props.activity.data.reduce((acc: number[], data: string) => {
    const parsedData = parseData(data);
    if (parsedData?.post_id && !acc.includes(parsedData.post_id)) {
      acc.push(parsedData.post_id);
    }
    return acc;
  }, []);

  const tips = props.activity.data.reduce((acc: string[], data: string) => {
    const parsedData = parseData(data);
    const tip = `${parsedData?.tip_amount} ${parsedData?.tip_currency}`;
    acc.push(tip);
    return acc;
  }, []);

  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        sent{" "}
        {tips?.length === 1 ? (
          <span className="font-bold">{tips[0]}</span>
        ) : (
          <>
            <strong>{tips?.length} </strong>
            {pluralize("tip", tips?.length)}
          </>
        )}{" "}
        on{" "}
        {postIds?.length === 1 ? (
          <Link to={`/post/${postIds[0]}`} className="font-bold">
            post #{postIds[0]}
          </Link>
        ) : (
          <>
            <strong>{postIds?.length} </strong>
            {pluralize("post", postIds?.length)}
          </>
        )}
      </div>
    </Item>
  );
}

function FollowedGameNotification(props: { activity: HappeningNowActivity }) {
  const [games, setGames] = React.useState<Game[]>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const gameIds: string[] = props.activity.data.map(
        (data: string) => parseData(data).target_game_id,
      );
      getGameData(gameIds).then((games) => {
        setGames(games.games);
        setLoading(false);
      });
    };

    fetchData();
  }, [props.activity]);

  if (loading) {
    return <SkeletonItem />;
  }

  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        started following {}
        {games?.length === 1 ? (
          <Link to={`/games/${games[0].slug}`} className="font-bold">
            {games[0].name}
          </Link>
        ) : (
          <>
            <strong>{games?.length} </strong>
            {pluralize("games", games?.length)}
          </>
        )}{" "}
        🕹️
      </div>
    </Item>
  );
}

function JoinedCommunityNotification(props: {
  activity: HappeningNowActivity;
}) {
  const [communities, setCommunities] = React.useState<Community[]>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const communityIds: string[] = props.activity.data.map(
        (data: string) => parseData(data).target_community_id,
      );
      getCommunityData(communityIds).then((communityData) => {
        setCommunities(communityData.communities);
        setLoading(false);
      });
    };

    fetchData();
  }, [props.activity]);

  if (loading) {
    return <SkeletonItem />;
  }

  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        joined{" "}
        {communities?.length === 1 ? (
          <Link
            to={`/communities/${communities[0].slug}`}
            className="font-bold"
          >
            {communities[0].name}
          </Link>
        ) : (
          <>
            <strong>{communities?.length} </strong>
          </>
        )}{" "}
        {pluralize("community", communities?.length)}🔥
      </div>
    </Item>
  );
}

function NewGameNotification(props: { activity: HappeningNowActivity }) {
  const [game, setGame] = React.useState<Game>();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const gameId = parseData(props.activity.data[0]).game_id;
      getGameData([gameId]).then((gameData) => {
        setGame(gameData.games[0]);
        setLoading(false);
      });
    };

    fetchData();
  }, [props.activity]);

  if (loading) {
    return <SkeletonItem />;
  }

  return (
    <Item activity={props.activity}>
      <div>
        A <strong>new game</strong> has been added to Soulbound:{" "}
        <Link to={`/games/${game?.slug}`} className="font-bold">
          {game?.name}
        </Link>
        🕹️
      </div>
    </Item>
  );
}

function NewQuestNotification(props: { activity: HappeningNowActivity }) {
  const [quest, setQuest] = React.useState<Quest>();
  const [game, setGame] = React.useState<Game>();
  const [community, setCommunity] = React.useState();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = parseData(props.activity.data[0]);
      const questId = data.quest_id;
      getQuestData([questId]).then((questData) => {
        setQuest(questData.quests[0]);
      });
      const ownerType = data.owner_type;
      if (ownerType === "game") {
        const gameId = data.game_id;
        getGameData([gameId]).then((gameData) => {
          setGame(gameData.games[0]);
          setLoading(false);
        });
        return;
      }
      if (ownerType === "community") {
        const communityId = data.community_id;
        getCommunityData([communityId]).then((communityData) => {
          setCommunity(communityData.communities[0]);
          setLoading(false);
        });
        return;
      }
    };

    fetchData();
  }, [props.activity]);

  if (loading) {
    return <SkeletonItem />;
  }

  return (
    <Item activity={props.activity}>
      <div>
        A new quest from{" "}
        {game ? (
          <Link to={`/games/${game?.slug}`} className="font-bold">
            {game?.name}
          </Link>
        ) : (
          <Link to={`/communities/${community?.slug}`} className="font-bold">
            {community?.name}
          </Link>
        )}{" "}
        has been added:{" "}
        <Link to={`/quests/${quest?.slug}`} className="font-bold">
          {quest?.name}
        </Link>
        🎯
      </div>
    </Item>
  );
}

function PostedCommentNotification(props: { activity: HappeningNowActivity }) {
  const uniquePostIds = props.activity.data.reduce(
    (acc: number[], data: string) => {
      const parsedData = parseData(data);
      if (parsedData?.postId && !acc.includes(parsedData.postId)) {
        acc.push(parsedData.postId);
      }
      return acc;
    },
    [],
  );
  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        commented on{" "}
        {uniquePostIds.map((id: number, index: number) => (
          <React.Fragment key={id}>
            <Link to={`/post/${id}`}>
              <strong>{id}</strong>
            </Link>
            {index !== uniquePostIds.length - 1 && ", "}
          </React.Fragment>
        ))}
      </div>
    </Item>
  );
}

function CreatedPostNotification(props: { activity: HappeningNowActivity }) {
  const [posts, setPosts] = React.useState<Post[]>([]);
  React.useEffect(() => {
    const extractPosts = async () => {
      const extractedPosts: Post[] = [];
      for (const data of props.activity.data) {
        const parsedData = parseData(data);
        extractedPosts.push(parsedData);
      }
      setPosts(extractedPosts);
    };

    extractPosts();
  }, [props.activity.data]);

  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        {posts.length === 1 ? (
          <>
            posted a new{" "}
            <Link to={`/post/${posts[0].id}`}>
              <strong>
                {posts[0].post_type === "video" ? "video 🎬" : "image 🖼️"}
              </strong>
            </Link>
          </>
        ) : (
          <>
            posted <strong>{posts.length}</strong> new posts 🎬🖼️
          </>
        )}
      </div>
    </Item>
  );
}

function SlappedStickerNotification(props: { activity: HappeningNowActivity }) {
  const parsedData = parseData(props.activity.data[0]);
  const totalPosts = new Set(parsedData.post_id).size;
  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`}>
          <strong>{props.activity.username}</strong>
        </Link>{" "}
        slapped on <strong> {totalPosts} </strong>
        {pluralize("post", totalPosts)}
      </div>
    </Item>
  );
}

function StickerSoldNotification(props: { activity: HappeningNowActivity }) {
  const [buyerUsernames, setBuyerUsernames] = React.useState<string[]>();
  const [sellerUsername, setSellerUsername] = React.useState<string>();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const fetchData = async () => {
      const sellerUserId = parseData(props.activity.data[0]).sellerUserId;
      const buyerUserIds = new Set(
        props.activity.data.map((data: string) => parseData(data).buyerUserId),
      );

      const uniqueUserIds = Array.from(
        new Set([sellerUserId, ...buyerUserIds]),
      );

      const users = await getUsernames(uniqueUserIds);

      setSellerUsername(users[sellerUserId]);
      setBuyerUsernames(Array.from(buyerUserIds).map((id) => users[id]));
      setLoading(false);
    };

    fetchData();
  }, [props.activity]);

  if (loading) {
    return <SkeletonItem />;
  }
  return (
    <Item key={props.activity.id} activity={props.activity}>
      <div>
        <Link to={`/${sellerUsername}`}>
          <strong>{sellerUsername}</strong>
        </Link>{" "}
        has just sold {pluralize("sticker", buyerUsernames?.length)} to{" "}
        {buyerUsernames?.map((name: string, i: number) => (
          <React.Fragment key={name + i}>
            <Link to={`/${name}`}>
              <strong>{name}</strong>
            </Link>
            {i !== buyerUsernames.length - 1 && ", "}
          </React.Fragment>
        ))}
      </div>
    </Item>
  );
}

function Item(props: {
  activity: HappeningNowActivity;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "flex gap-2 bg-[rgba(255,255,255,0.30)] rounded-lg p-2 ",
        props.className,
      )}
    >
      <Link to={`/${props.activity.username}`}>
        <div
          className="w-10 h-10 rounded-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${
              props.activity.user_avatar_url || "/default-avatar.svg"
            })`,
          }}
        />
      </Link>
      <div className="flex flex-col">
        <div className="text-sm">{props.children}</div>
        <p className="text-xs">
          {formatDistanceToNowStrict(
            parseISO(props.activity.created_at as string),
            {
              addSuffix: true,
            },
          )}
        </p>
      </div>
    </div>
  );
}

async function getUsernames(userIds: string[]) {
  return fetch("/api/users/usernames", {
    method: "POST",
    body: JSON.stringify({ userIds }),
  })
    .then((res) => res.json())
    .then((res) => res.users);
}

async function getGameData(gameIds: string[]) {
  return fetch("/api/games", {
    method: "POST",
    body: JSON.stringify({ gameIds }),
  }).then((res) => res.json());
}

async function getQuestData(questIds: string[]) {
  return fetch("/api/quests", {
    method: "POST",
    body: JSON.stringify({ questIds }),
  }).then((res) => res.json());
}

async function getCommunityData(communitiesIds: string[]) {
  return fetch("/api/communities", {
    method: "POST",
    body: JSON.stringify({ communitiesIds }),
  }).then((res) => res.json());
}

interface HappeningNowProps {
  items: number;
}
export function HappeningNow(props: HappeningNowProps) {
  const [happeningNow, setHappeningNow] = React.useState<
    HappeningNowActivity[]
  >([]);
  const [loading, setLoading] = React.useState(true);
  const [showHappeningNowModal, setShowHappeningNowModal] =
    React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    fetch("/api/happening-now", {
      method: "POST",
      body: JSON.stringify({ limit: props.items }),
    })
      .then((res) => res.json())
      .then((res) => setHappeningNow(res))
      .catch((error) =>
        console.error("Error fetching happening now data:", error),
      )
      .finally(() => setLoading(false));
  }, [props.items]);

  if (!loading && happeningNow?.length === 0) {
    return (
      <div className="py-10 flex items-center justify-center">
        <p className="text-base">No activities yet.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <h2 className="font-medium text-[#6637CE]">Happening now</h2>
        <button
          type="button"
          className="text-[#5B6684] text-sm font-medium outline-none"
          onClick={() => setShowHappeningNowModal(true)}
        >
          See more
        </button>
      </div>
      {loading ? (
        [...Array(props.items).keys()].map((_, emptyIndex) => (
          <SkeletonItem key={emptyIndex} />
        ))
      ) : (
        <div className="flex flex-col gap-2">
          {happeningNow?.map((activity, index) => (
            <React.Fragment key={index}>
              {renderNotification(activity)}
            </React.Fragment>
          ))}
        </div>
      )}
      {showHappeningNowModal && (
        <HappeningNowModal
          open={showHappeningNowModal}
          onClose={() => setShowHappeningNowModal(false)}
        />
      )}
    </div>
  );
}

interface SkeletonItemProps {
  className?: string;
}

function SkeletonItem(props: SkeletonItemProps) {
  return (
    <div
      className={cn(
        "flex gap-2 bg-[rgba(255,255,255,0.30)] rounded-xl p-2",
        props.className,
      )}
    >
      <div className="w-10 h-10 rounded-full bg-gray-300 animate-pulse" />
      <div className="flex flex-col justify-center">
        <div className="h-3 bg-gray-300 w-52 animate-pulse mb-1" />
        <div className="h-3 bg-gray-300 w-24 animate-pulse" />
      </div>
    </div>
  );
}

function renderNotification(activity: HappeningNowActivity) {
  switch (activity.activity_type) {
    case "new_signup":
      return <SignupNotification activity={activity} />;
    case "claimed_drip":
      return <ClaimedDripNotification activity={activity} />;
    case "posted_comment":
      return <PostedCommentNotification activity={activity} />;
    case "sticker_sold":
      return <StickerSoldNotification activity={activity} />;
    case "followed_user":
      return <FollowedUserNotification activity={activity} />;
    case "followed_game":
      return <FollowedGameNotification activity={activity} />;
    case "joined_community":
      return <JoinedCommunityNotification activity={activity} />;
    case "created_post":
      return <CreatedPostNotification activity={activity} />;
    case "slapped_sticker":
      return <SlappedStickerNotification activity={activity} />;
    case "new_game":
      return <NewGameNotification activity={activity} />;
    case "new_quest":
      return <NewQuestNotification activity={activity} />;
    case "tip_sent":
      return <TipSentNotification activity={activity} />;
    case "mentioned_game":
      return <MentionedGameNotification activity={activity} />;
    case "mentioned_community":
      return <MentionedCommunityNotification activity={activity} />;
    case "streaming_now":
      return <StreamingNowNotification activity={activity} />;
    case "made_a_bet":
      return <MadeABet activity={activity} />;
    case "new_contest":
      return <NewContestNotification activity={activity} />;
    default:
      return null;
  }
}

interface HappeningNowModalProps {
  open: boolean;
  onClose: () => void;
}

const LIMIT = 30;

export function HappeningNowModal(props: HappeningNowModalProps) {
  const [happeningNow, setHappeningNow] = React.useState<
    HappeningNowActivity[]
  >([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    fetch("/api/happening-now", {
      method: "POST",
      body: JSON.stringify({ limit: LIMIT }),
    })
      .then((res) => res.json())
      .then((res) => setHappeningNow(res))
      .catch((error) =>
        console.error("Error fetching happening now data:", error),
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      className={cn(
        "relative w-full lg:w-[40%] mx-auto overflow-hidden min-h-[80%]",
      )}
      render={(dialogProps) => (
        <div
          className={cn(
            "flex fixed z-50 top-0 p-4 w-full h-full bg-primary/50 backdrop-blur-sm flex-col justify-center items-center",
            props.open ? "flex" : "hidden",
          )}
        >
          <div {...dialogProps} />
        </div>
      )}
    >
      <div className="bg-white rounded-xl border-primary border h-full">
        <div className="flex items-center justify-between p-4 border-b">
          <p className="font-bold text-lg">Happening Now</p>
          <button type="button" onClick={props.onClose}>
            <X />
          </button>
        </div>
        <div className="h-[calc(100%-60px)] bg-[#e9ecff] rounded-b-lg">
          <Scrollbars universal>
            <div className="p-4">
              {loading ? (
                <div className="flex flex-col gap-2">
                  {Array(8)
                    .fill(null)
                    .map((_, index) => (
                      <SkeletonItem key={index} className="bg-white" />
                    ))}
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  {happeningNow.map((activity, activityIndex) => (
                    <div className="bg-white rounded-lg" key={activityIndex}>
                      {renderNotification(activity)}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Scrollbars>
        </div>
      </div>
    </Dialog>
  );
}

function StreamingNowNotification(props: { activity: HappeningNowActivity }) {
  const data = parseData(props.activity.data[0]);
  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`} className="font-semibold">
          {props.activity.username}
        </Link>{" "}
        is now Streaming at{" "}
        <a
          href={`https://www.twitch.tv/${data.broadcaster_user_login}`}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 hover:underline font-semibold"
        >
          {`https://www.twitch.tv/${data.broadcaster_user_login}`}
        </a>
      </div>
    </Item>
  );
}

function MadeABet(props: { activity: HappeningNowActivity }) {
  const data = parseData(props.activity.data[0]);
  return (
    <Item activity={props.activity}>
      <div>
        <Link to={`/${props.activity.username}`} className="font-semibold">
          {props.activity.username}
        </Link>{" "}
        made a bet for <strong>{data.option}</strong> on{" "}
        <strong>{data.title}</strong>
      </div>
    </Item>
  );
}

function NewContestNotification(props: { activity: HappeningNowActivity }) {
  const data = parseData(props.activity.data[0]);
  const urlObj = new URL(data.stream_url);
  const channel = urlObj.searchParams.get("channel");
  return (
    <Item activity={props.activity}>
      <div>
        New Contest: {data?.title} for{" "}
        <a
          href={`https://www.twitch.tv/${channel}`}
          target="_blank"
          rel="noreferrer"
          className="font-medium text-blue-500 hover:underline"
        >
          {channel}
        </a>{" "}
        has been added.
      </div>
    </Item>
  );
}
