import { Scrollbars } from "react-custom-scrollbars";
import * as config from "~/config";
import { useIsCollapse } from "~/hooks/use-is-collapse";
import { cn } from "~/util/css";
import { HappeningNow } from "./happening-now";
import { TrendingPosts } from "./trending-posts";
import { TrendingStickers } from "./trending-stickers";
import { TwitchLiveStreams } from "./twitch-live-streams";

interface RightSidebar {
  className: string;
  isPost?: boolean;
}
export function RightSidebar(props: RightSidebar) {
  const [isCollapse] = useIsCollapse();
  return (
    <div
      className={cn(
        "w-[360px] hidden xl:block h-full",
        {
          "w-[350px]": isCollapse,
        },
        props.className,
      )}
    >
      <Scrollbars universal autoHide>
        <div className={cn("flex flex-col gap-6 py-6")}>
          {props.isPost ? (
            <>
              <div
                className="rounded-lg p-3"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(205, 187, 255, 0.16) 0%, rgba(205, 187, 255, 0.16) 100%), linear-gradient(58deg, #DDDCFF 28.45%, #F0F2FF 57.03%, #DCDCFF 82.05%)",
                }}
              >
                <TrendingPosts items={9} cols={3} />
              </div>
              <div
                className="rounded-lg p-3"
                style={{
                  background:
                    "linear-gradient(0deg, rgba(205, 187, 255, 0.16) 0%, rgba(205, 187, 255, 0.16) 100%), linear-gradient(58deg, #DDDCFF 28.45%, #F0F2FF 57.03%, #DCDCFF 82.05%)",
                }}
              >
                <TrendingStickers items={9} cols={3} />
              </div>
            </>
          ) : (
            <>
              {config.features.enableTwitchLiveStreams && <TwitchLiveStreams />}
              <HappeningNow items={5} />
              <TrendingPosts items={4} />
              <TrendingStickers items={4} />
            </>
          )}
        </div>
      </Scrollbars>
    </div>
  );
}
