import { Link } from "@remix-run/react";
import { useQuery } from "@tanstack/react-query";
import type { TrendingStickersActivity } from "~/types";

interface TrendingStickerProps {
  items: number;
  cols?: number;
}

const getTrendingStickers = async (): Promise<TrendingStickersActivity[]> => {
  try {
    const res = await fetch(`${window.ENV.EDGE_URL}/stickers/trending`);
    const data = await res.json();
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export function TrendingStickers(props: TrendingStickerProps) {
  const { data, isLoading: loading } = useQuery({
    queryKey: ["trending-stickers"],
    queryFn: () => getTrendingStickers(),
  });

  const trendingStickers = data ? data.slice(0, props.items) : [];

  if (!loading && !trendingStickers.length) {
    return (
      <div className="py-10 flex items-center justify-center">
        <p className="text-base">No trending stickers yet.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <h2 className="font-medium text-[#6637CE]">Trending stickers</h2>
      </div>
      {loading ? (
        <SkeletonItem cols={props.cols} />
      ) : (
        <div className={`grid grid-cols-${props.cols || 4} gap-2`}>
          {trendingStickers?.map((sticker) => (
            <div
              key={sticker.id}
              className="rounded-lg overflow-hidden group bg-[linear-gradient(118deg,#E0FEF6_27.59%,rgba(127,124,255,0.30)_118.93%)] hover:bg-[#BC4CDE] hover:bg-none p-[1px] relative"
            >
              <div className="h-32 rounded-lg overflow-hidden w-full bg-[linear-gradient(122deg,rgba(255,164,220,0.37)_16.13%,rgba(107,104,255,0.30)_98.86%)] group-hover:bg-[linear-gradient(122deg,#987EF7_16.13%,rgba(107,104,255,0.30)_98.86%)]">
                <Link to={`/sticker/${sticker.id}`}>
                  <div className="h-24">
                    <img
                      src={sticker.image_url}
                      alt={sticker.id}
                      className="w-full h-full object-contain object-center"
                    />
                  </div>
                </Link>
                <Link to={`/${sticker.owner_username}`}>
                  <div className="p-2 bg-white bg-opacity-75 flex items-center gap-1 h-8">
                    <img
                      src={sticker.owner_avatar_url || "/default-avatar.svg"}
                      alt={sticker.owner_username}
                      className="w-5 h-5 rounded-full border border-white"
                    />
                    <p className="font-medium text-xs truncate">
                      {sticker.owner_username}
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export function SkeletonItem(props: { cols?: number }) {
  return (
    <div className={`grid grid-cols-${props.cols || 4} gap-2`}>
      {Array.from({ length: 4 }).map((_, index) => (
        <div
          key={index}
          className="relative rounded-lg overflow-hidde p-[1px] animate-pulse"
        >
          <div className="h-32 rounded-lg overflow-hidden bg-gray-300 w-full animate-pulse">
            <div className="h-26 bg-gray-300" />
            <div className="flex-wrap p-2 bg-[rgba(255,255,255,0.75)] flex items-center gap-1 w-full absolute bottom-0 rounded-b-lg">
              <div className="flex items-center flex-grow gap-2">
                <div className="w-5 h-5 rounded-full bg-gray-300" />
                <div className="h-3 bg-gray-300 w-12 animate-pulse" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
